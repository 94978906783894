/** @format */

import axios from 'axios';

export const API_KEY_AUTHENTICATION_STAGING = '200fe7fa-f61d-4baf-8583-417d1e6a7861'; // DV API TBD
export const API_KEY_AUTHENTICATION_PRODUCTION = '200fe7fa-f61d-4baf-8583-417d1e6a7861'; // DV API TBD

export const getConfig = () => {
  switch (process.env.NEXT_PUBLIC_APP_ENV) {
    case 'prod':
      return 'https://colivingapi.joivy.com';
    case 'develop': // prev staging
      return 'https://test-colivingapi.joivy.com';
    default:
      throw new Error(`Invalid NEXT_PUBLIC_APP_ENV: ${process.env.NEXT_PUBLIC_APP_ENV}`);
  }
};

export const AxiosConfig = () => {
  const baseConfig = {
    headers: { 'Content-Type': 'application/json', 'X-API-KEY': API_KEY_AUTHENTICATION_STAGING },
  };

  const prodBaseConfig = {
    headers: { 'Content-Type': 'application/json', 'X-API-KEY': API_KEY_AUTHENTICATION_PRODUCTION },
  };

  switch (process.env.NEXT_PUBLIC_APP_ENV) {
    default:
    case 'prod':
      return { ...prodBaseConfig, baseURL: getConfig() };
    case 'staging':
      return { ...baseConfig, baseURL: getConfig() };
    case 'develop':
      return { ...baseConfig, baseURL: getConfig() };
  }
};

const axiosInstance = axios.create(AxiosConfig());
export const Axios = axiosInstance;
